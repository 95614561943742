const NUMBER_VALIDATION = /^\d+$/
const ALPHA_VALIDATION = /[^A-Z a-z]+/;



export default class CommonValidation {
    static alphabetOnly = (value) => {
        return !ALPHA_VALIDATION.test(value)
    }

    static numberOnly = (value) => {
        return NUMBER_VALIDATION.test(value)
    }
}