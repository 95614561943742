export default class ImagePaths {
    static UMAHeaderImage = require('../Assets/Images/UMAHeader.png')
    static UMAFooterImage = require('../Assets/Images/UMAFooter.png')
    static MasonicHeaderImage = require("../Assets/Images/MasonicHeader.jpg")
    static AppStore = require("../Assets/Images/appstore.png")
    static PlayStore = require("../Assets/Images/playstore.png")
    static Logo = require("../Assets/Images/eMedHub_transprent.png")
    static LoinLeftImg = require("../Assets/Images/loginBackground.svg")
    static ShivaniHeaderImage = require("../Assets/Images/shivaniHeader.png")
    static RajeshHeader = require("../Assets/Images/RajeshHeader.jpeg")
    static AppStoreNew = require('../Assets/Images/Appstore_New.png')
    static PlayStoreNew = require('../Assets/Images/Playstore_New.png')
}