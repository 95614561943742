import React, { useState, useEffect } from 'react';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';

const ToastMsg = ({ msg, msgtop, msgPop, severity, testId }) => {
    const [toastOpen, setToastOpen] = useState(true);
    const vertical = msgtop ? 'top' : 'bottom';
    const horizontal = msgtop ? 'center' : 'left';

    useEffect(() => {
        const timer = setTimeout(() => {
            msgPop();
        }, 3000);

        return () => {
            clearTimeout(timer);
        };
    }, [msgPop]);

    const handleClose = () => {
        setToastOpen(false);
    };

    return (
        <div>
            <Snackbar open={toastOpen} anchorOrigin={{ vertical, horizontal }} key={vertical + horizontal}>
                <Alert invoice_number={testId} emed_tid={'toast'} onClose={handleClose} variant="filled" severity={severity} sx={{ width: '100%' }}>
                    {msg}
                </Alert>
            </Snackbar>
        </div>
    );
};

const setCachevalue = (value, key) => {
    sessionStorage.setItem(key, value);
}
const getCachevalue = (key) => {
    let value = sessionStorage.getItem(key);
    return value;
}
const clearCachevalue = (key) => {
    sessionStorage.removeItem(key);
}
const clearAllCachevalue = () => {
    sessionStorage.clear();
}
const localSetItem = (key, value) => {
    return localStorage.setItem(key, value);
}
const localGetItem = (key) => {
    return localStorage.getItem(key);
}
const localClearItem = (key) => {
    return localStorage.removeItem(key);
}
const localClearAll = () => {
    return localStorage.clear();
}
const calculateBirthdateFromAge = (dob) => {
    // Parse the date of birth
    const birthDate = new Date(dob);
    const today = new Date();

    // Calculate the difference in years
    let age = today.getFullYear() - birthDate.getFullYear();

    // Adjust if the birthday hasn't occurred yet this year
    const monthDiff = today.getMonth() - birthDate.getMonth();
    const dayDiff = today.getDate() - birthDate.getDate();

    if (monthDiff < 0 || (monthDiff === 0 && dayDiff < 0)) {
        age--;
    }

    return age;
}

const calculateAgeFromBirthdate = (age) => {
    const today = new Date();
    const birthYear = today.getFullYear() - age;

    // Assuming the person was born on today's date in the calculated year
    const birthDate = new Date(birthYear, today.getMonth(), today.getDate());

    return birthDate.toISOString().split('T')[0]; // Format: YYYY-MM-DD
}
export {
    ToastMsg, setCachevalue, getCachevalue, clearCachevalue, clearAllCachevalue, localSetItem, localGetItem, localClearItem, localClearAll,
    calculateBirthdateFromAge, calculateAgeFromBirthdate
}